const waybill_router = [
  {
    path: '/waybilllimit',
    name: 'waybilllimit',
    component: () => import('@/views/waybill/waybill-limit.vue'),
    meta: {
      title: '运单充值'
    }
  },
  {
    path: '/agency/limit/waybill',
    name: 'agencylimitwaybill',
    component: () => import('@/views/waybill/waybill-limit-agency.vue'),
    meta: {
      title: '代理商运单充值记录'
    }
  },
  {
    path: '/waybill/share',
    name: 'waybillshare',
    component: () => import('@/views/waybill/waybill-share.vue'),
    meta: {
      title: '运单分享'
    }
  },
  {
    path: '/waybill/share/details/:code',
    name: 'waybillsharedetails',
    component: () => import('@/views/waybill/waybill-share-details.vue'),
    meta: {
      title: '运单分享列表'
    }
  },
  {
    path: '/waybill/share/list',
    name: 'waybillsharelist',
    component: () => import('@/views/waybill/waybill-share-list.vue'),
    meta: {
      title: '运单分享列表'
    }
  },
  {
    path: '/waybill/traces',
    name: 'waybilltraces',
    component: () => import('@/views/waybill/waybill-traces.vue'),
    meta: {
      title: '运单轨迹'
    }
  },
  {
    path: '/waybill/share/agency',
    name: 'waybillshareagency',
    component: () => import('@/views/waybill/waybill-share-agency.vue'),
    meta: {
      title: '代理商运单分享'
    }
  }
]

export default waybill_router
// 运单
