const finance = [
  {
    path: '/finance/freight/list',
    name: 'freight-list',
    component: () => import('@/views/finance/freight-list.vue'),
    meta: {
      title: '订舱人'
    }
  },
  {
    path: '/finance/freight/setting',
    name: 'freight-setting',
    component: () => import('@/views/finance/freight-setting.vue'),
    meta: {
      title: '海关申请单配置'
    }
  }
]
export default finance
// 配置相关
